import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import SellingPoints from "../components/SellingPoints"
import BottomCta from "../components/BottomCta"

const IndexPage = () => (
  <Layout>
    <SEO title="SalesScript - Home" />
    <Hero />
    <SellingPoints />
    <BottomCta />
  </Layout>
)

export default IndexPage
