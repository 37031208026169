import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function SellingPoints({ podcast }) {
  return (
    <div className="relative rounded-2xl p-2 md:p-8 my-20 bg-light flex flex-col justify-between items-center text-center">
      <h2 className="text-baseBlue p-4 max-w-xl font-semibold">
        Sales isn’t about convincing people to buy your product. It’s about
        inviting your customer into a story where their problem gets solved.
      </h2>
      <p className="text-baseBlue mb-8 text-lg tracking-wide">
        After you create your Million-Dollar Sales Pitch, you will…
      </p>
      <div className="flex flex-col md:flex-row">
        <div className="selling-point-item">
          <StaticImage
            src="../images/Invite.png"
            alt="Invitation"
            height={80}
            className="selling-point-image"
          />
          <p className="selling-point-copy">
            Start inviting customers into a story instead of merely pushing
            products
          </p>
        </div>
        <div className="selling-point-item">
          <StaticImage
            src="../images/Formula.png"
            alt="Sales Formula"
            height={80}
            className="selling-point-image"
          />
          <p className="selling-point-copy">
            Have a proven formula you can use over and over again to close
            millions in revenue.
          </p>
        </div>
        <div className="selling-point-item">
          <StaticImage
            src="../images/Pitch.png"
            alt="Sales Formula"
            height={80}
            className="selling-point-image"
          />
          <p className="selling-point-copy">
            Confidently close more deals and have a lot more fun doing it.
          </p>
        </div>
      </div>
      <div className="m-4">
        <a
          href={`https://app.onlinesalesscript.com/${
            podcast ? "podcast" : "register"
          }`}
          target="_blank"
          rel="noreferrer"
          className="Button-retro Button-retro_dark "
        >
          <span className="hidden xs:block">
            CREATE YOUR MILLION-DOLLAR SALES PITCH
          </span>
          <span className="xs:hidden block">CREATE YOUR SALES PITCH</span>
        </a>
      </div>
    </div>
  )
}
