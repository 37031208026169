import React from "react"
import ApplicationLogo from "./ApplicationLogo"

export default function BottomCta({ podcast }) {
  return (
    <div className="relative rounded-2xl bg-baseBlue flex flex-col md:flex-row justify-between items-center  p-6">
      <div className="left text-white">
        <ApplicationLogo siteTitle="Online SalesScript" />
      </div>
      <div className="right flex items-center justify-between">
        <a
          href="https://app.onlinesalesscript.com/login"
          target="_blank"
          rel="noreferrer"
          className="Button-login_white"
        >
          Log In
        </a>
        <a
          href={`https://app.onlinesalesscript.com/${
            podcast ? "podcast" : "register"
          }`}
          target="_blank"
          rel="noreferrer"
          className="Button-retro Button-retro_light"
        >
          Create Your Sales Pitch
        </a>
      </div>
    </div>
  )
}
