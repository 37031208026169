import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Hero({ podcast }) {
  return (
    <div className="relative rounded-2xl bg-baseBlue flex flex-col md:flex-row justify-between items-center pb-2">
      <div
        className={`pl-12 lg:pl-16 pt-8 pr-12 md:pr-4  text-white w-full md:w-5/12 text-center md:text-left flex flex-col justify-center items-center ${
          podcast ? "xl:pr-4" : "xl:pr-10"
        }`}
      >
        <h1>Use the Power of Story to Create a Million-Dollar Sales Pitch</h1>
        <div className="w-full">
          <a
            className={`Button-retro Button-retro_light my-8 m-auto md:ml-0 w-full ${
              podcast ? "mb-4" : ""
            }`}
            href={`https://app.onlinesalesscript.com/${
              podcast ? "podcast" : "register"
            }`}
            target="_blank"
            rel="noreferrer"
          >
            Create Your Sales Pitch
          </a>
          {podcast ? (
            <p className="text-left text-[15px] ml-0 w-full">
              <em>Plus exclusive bonus videos for podcast listeners</em>
            </p>
          ) : null}
        </div>
      </div>
      <div className="right relative w-full lg:w-7/12 md:w-8/12">
        <div className="-mb-[5.5rem] flex justify-center md:justify-end">
          <StaticImage
            src="../images/OSS-Site-Header.png"
            alt="SalesScript Demo"
            height={550}
          />
        </div>
      </div>
    </div>
  )
}
